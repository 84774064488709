import gql from 'graphql-tag'

export const USER_PROFILE_FRAGMENT = `
address
createdAt
email
familyName
givenName
id
industry
jobTitle
locale
phoneNumber
timezone
intercomIdentityEmailHash
updatesNotifiedAt
requireTermsAcceptance
referrerCode
referralPortalReferrerMemberCode
`

export const GATEWAY_FEATURES_FRAGMENT = `
emailAuditing,
referrals,
referralPortal,
googleAuth,
suretyBonds
`
const ORGANISATION_SHARED_FRAGMENT = `
id
abn
name
address
capabilities
channelPartnerOrganisations{
  nodes{
    channelPartner{
      name
      id
    }
  }
}
industryType
postalAddress
postalAddressSameAsBusinessAddress
organisationStructure
referralCode
`
const ORGANISATION_METADATA_SHARED_FRAGMENT = `
documentMetadata(order_by: { key: ASC }) {
  id
  key
}
`
export const DOCUMENTS_REPORT_ISSUERS_FRAGMENT = `
issuers: parties(where: { partyVariant: ISSUER }) {
  abn
  id
  name
  partyVariant
  address
  notes
}
`
export const DOCUMENTS_REPORT_BENEFICIARIES_FRAGMENT = `
beneficiaries: parties(where: { partyVariant: BENEFICIARY }) {
  abn
  id
  name
  partyVariant
  address
  notes
}
`
export const DOCUMENTS_REPORT_METADATA_FRAGMENT = `
metadata {
  nodes {
    key
    value
  }
}
`
export const DOCUMENTS_REPORT_COSTS_FRAGMENT = `
costs {
  setUpCost
  recurringFixedCost
  recurringPercentageCost
  recurringCostFrequency
  adjustedCost
  recurringFeeType
  recurringMinimumAnnualCost
}
`
const DRAFT_HISTORY_SHARED_FRAGMENT = `
nodes {
  changes {
    label
    field
    newValue
    newDisplayValue
    oldValue
    oldDisplayValue
  }
  changeSetId
  changeType
  id
  objectType
  recordName
  triggeredAt
  triggeredBy {
    familyName
    givenName
    isBankAuthor
  }
}
totalCount
pageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}`

export const DOCUMENT_QUERY_SHARED_FRAGMENT = `
agreementContent
amount
bank {
  bsb
  name
  address
}
invoices{
  canGenerateHostedInvoice
  hostedInvoiceAttachmentId
  depositReferenceNumber
  stripeInvoiceHostedUrl
  stripeInvoicePdf
  stripeInvoiceStatus
  stripeReceiptUrl
  createdAt
  invoiceLineItems{
    amount
    description
  }
  invoicePaymentIntents{
    stripePaymentIntentId
    status
    createdOn
  }
  totalAmount
}
supplierName
createdAt
capabilities
documentVariant
establishedAt
actionDate
closedAt
expiresAt
expectedReturnDate
guaranteeNumber
id
organisationId
originalDraftId
parties {
  id
  abn
  address
  name
  notes
  partyVariant
}
projectAddress
referenceNumber
requestedAt
status
retentionType
securityType
isSystemDocument
description
purpose
specifiedOtherPurpose
attachments {
  id
  fileName
  contentType
  received
  uploadPresignedUrl
  infectedFileStatus
}
metadata(order_by: { key: ASC }) {
  nodes {
    id
    key
    value
  }
}
collaborators {
  nodes {
    id
    emailAddress
    capabilities
  }
}
custodian {
  emailAddress
  name
  notes
  storageAddress
  dateReceived
}
handover {
  emailAddress
  name
  organisationName
  createdOn
}
requestForReturn {
  emailAddress
  recurringFrequency
  name
  startedOn
}
callerContext {
  organisation {
    name
    id
  }
isOrganisationMember
}
costs {
  setUpCost
  recurringMinimumAnnualCost
  recurringFixedCost
  recurringPercentageCost
  recurringCostFrequency
  recurringFeeType
  adjustedCost
}
facilityId
interestStartDate
interestEndDate
purpose
history(first: 25, order_by: { triggeredAt: DESC }) {
  ${DRAFT_HISTORY_SHARED_FRAGMENT}
}
`
export const DRAFT_QUERY_SHARED_FRAGMENT = `
  id
  organisationId
  status
  discountPercentage
  completedFinanceJourney
  amount
  purpose
  expiresAt
  expectedReturnDate
  agreementContent
  capabilities
  processType
  projectAddress
  referenceNumber
  issuerSourceOfFunds
  currentPeriodEnd
  issuerDetailsOfSourceOfFunds
  isVisibleToSupplier
  directorFirstName
  directorLastName
  directorEmailAddress
  feeSummaryId
  feeSummary{
    biannualFeeCoefficient
    establishmentFee
    feeSummaryStatus
    name
    recurringFeePercentage
    supplier{
      name
    }
  }
  megDetails
  deliveryDetails{
    address
    recipientFamilyName
    recipientGivenName
    phoneNumber
    companyName
  }
  applicableRequirements {
    message
    requirementMet
  }
  attachments {
    id
    contentType
    fileName
    received
    variant
    infectedFileStatus
  }
  contract {
    bankFrontedBondsRequested
    bankFrontedBondsRequestedReason
    practicalCompletionDate
    hasPreviousContracts
    hasSimilarContracts
    isSubcontractor
    hasDesignObligationsLiability
    defectsAndFaults {
      active
      detail
    }
    defectsLiabilityPeriodExtended{
      active
      detail
    }
    finalCompletionDate
    hasDelayEvents
    isExistingProject
    issuesAndDisputes {
      active
      detail
    }
    projectNotOnBudget
    replacingExistingSecurity {
      active
      detail
    }
    totalValue
    startDate
  }
  requestedSuretyDocuments {
    id
    amount
    periodStartDate
    periodEndDate
    purpose
    expiryDate
    documentWording
    documentWordingTemplate
  }
  parties {
    id
    abn
    address
    name
    notes
    partyVariant
    partyEntityType
    primaryContact {
      email
      familyName
      givenName
      phoneNumber
    }
  }
  draftCollaborators{
    nodes {
      id
      capabilities
      emailAddress
      familyName
      givenName
      organisationName
      roles
      hasApproved
      invitationStatus
      subscribedMembers {
        familyName
        givenName
        memberId
      }
    }
  }
  specifiedOtherPurpose
  callerContext {
    collaborator {
      id
      capabilities
      emailAddress
      familyName
      givenName
      organisationName
      roles
      hasApproved
      subscribedMembers {
        familyName
        givenName
        memberId
      }
    }
    organisation {
      id
      name
    }
    isOrganisationMember
  }
  invoices{
    createdAt
    canGenerateHostedInvoice
    hostedInvoiceAttachmentId
    hostedReceiptAttachmentId
    depositReferenceNumber
    stripeInvoiceHostedUrl
    stripeInvoicePdf
    stripeInvoiceStatus
    stripeReceiptUrl
    invoiceLineItems{
      amount
      description
    }
    invoicePaymentIntents{
      stripePaymentIntentId
      status
      createdOn
    }
    total
  }
  stripeSubscriptionStatus
  stripeSubscriptionId
  paymentDetails {
    bankAccountBsb
    bankAccountName
    bankAccountNumber
    establishmentFee
    paymentDescription
    requestedAt
    securityAmount
    lineItems {
      amount
      description
    }
  }
  requestChangeReason
  rejectionReason
  totalFees
  facilityId
  invoiceRecipientEmailAddresses
`

export const DRAFT_BG_SWITCH_FRAGMENT = `
  affirmationIrrevocability
  beneficiaryApproval
  currentBankFullName
  currentExpiryDate
  currentGuaranteeNumber
  currentSecurityType
  currentValue
  id
  indemnityAgreement
  irrevocableOrder
  isCashInTermDeposit
  organisationId
  securityInterestDeclaration
  status
  termDepositEndDate
  transactionUnwindingClause
`

const DRAFT_COMMENTS_SHARED_FRAGMENT = `
nodes {
  id
  author {
    familyName
    givenName
  }
  content
  createdAt
  history {
    changes {
      label
      field
      newValue
      newDisplayValue
      oldValue
      oldDisplayValue
    }
    triggeredBy {
      familyName
      givenName
    }
    changeType
    objectType
    recordName
    changeSetId
  }
}
totalCount
pageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}`

export const USER_STATE_QUERY = gql`
  query UserState {
    organisations {
      nodes {
        id
        abn
        name
        capabilities
        address
        industryType
        members {
          totalCount
        }
        discountPercentage
        documentsCapabilities
      }
    }
    me {
      ${USER_PROFILE_FRAGMENT}
    }
  }
`

export const REQUIRED_DOCUMENTS_QUERY_SHARED_FRAGMENT = `
requiredDocuments(order_by: { dateCreated: DESC }) {
createdByAdminUserId
        dateCreated
        draftId
        id
        requiredDocumentFlow
        overallStatus
        requiredDocumentType
        requiredDocumentMessages(order_by: { dateCreated: DESC }) {
          attachmentId
          createdByUserId
          canAuthorizedUserAcceptOrReject
          createdByUser {
            familyName
            givenName
          }
          dateCreated
          fromParty
          hasApproval
          id
          parentMessageId
          isAutomatedMessage
          message
          requiredDocumentId
          attachment {
            id
            draftId
            infectedFileStatus
            fileName
            received
          }
        }
      }
`

export const GATEWAY_FEATURES_QUERY = gql`
  query GatewayFeatures {
    features{
      ${GATEWAY_FEATURES_FRAGMENT}
    }
  }
`

export const GET_IDENTIFICATION_INDIVIDUAL_GEENID_TOKEN = gql`
  query initializeGreenId($identificationIndividualId: ID) {
    initializeGreenId(identificationIndividualId: $identificationIndividualId) {
      id
      simpleKycDataIndividualRecord {
        address {
          country
          country_name
          number
          postalCode
          state
          street
          type
          unformattedAddress
          unit
          suburb
        }
        dateOfBirth
      }
      firstName
      middleNames
      surname
      emailAddress
      greenIdVerificationId
      greenIdVerificationStatus
      greenIdToken
    }
  }
`

export const ORGANISATIONS_QUERY = gql`
  query Organisations($where: OrganisationFilter!) {
    organisations(where: $where) {
      nodes {
        id
        abn
        name
        capabilities
        members {
          totalCount
        }
      }
    }
  }
`

export const ORGANISATION_QUERY = gql`
  query OrganisationById($organisationId: ID!) {
    organisations(where: { id: $organisationId }) {
      nodes {
        ${ORGANISATION_SHARED_FRAGMENT}
      }
    }
  }
`

export const ORGANISATION_METADATA_QUERY = gql`
  query OrganisationMetadata($organisationId: ID!) {
    organisations(where: { id: $organisationId }) {
      nodes {
        id
        ${ORGANISATION_METADATA_SHARED_FRAGMENT}
      }
    }
  }
`

export const ORGANISATION_AND_METADATA_QUERY = gql`
  query OrganisationAndMetadata($organisationId: ID!) {
    organisations(where: { id: $organisationId }) {
      nodes {
        ${ORGANISATION_SHARED_FRAGMENT}
        ${ORGANISATION_METADATA_SHARED_FRAGMENT}
      }
    }
  }
`

export const SEARCH_ORGANISATION_MEMBERS = gql`
  query SearchOrganisationMembers(
    $organisationId: ID!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $search: String!
  ) {
    searchOrganisationMembers(
      organisationId: $organisationId
      after: $after
      before: $before
      first: $first
      last: $last
      search: $search
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        createdAt
        familyName
        givenName
        memberId
        role
        userId
      }
    }
  }
`

export const DOCUMENTS_QUERY = gql`
  query DocumentsByOrganisation(
    $organisationId: ID!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: DocumentSort
    $where: DocumentFilter
    $search: String
  ) {
    documents(
      organisationId: $organisationId
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
      search: $search
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        organisationId
        referenceNumber
        status
        documentVariant
        amount
        projectAddress
        expectedReturnDate
        expiresAt
        expectedReturnDate
        createdAt
        supplierName
        bank {
          name
        }
      }
    }
  }
`

export const DOCUMENTS_REPORT_QUERY = `
  query DocumentsByOrganisationForReport(
    $organisationId: ID!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: DocumentSort
    $where: DocumentFilter
    $search: String
    $metadataFilters: [DocumentMetadataFilterInput]
  ) {
    documents(
      organisationId: $organisationId
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
      search: $search
      metadataFilters: $metadataFilters
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        agreementContent
        amount
        createdAt
        documentVariant
        establishedAt
        expectedReturnDate
        projectAddress
        expiresAt
        expectedReturnDate
        actionDate
        closedAt
        purpose
        guaranteeNumber
        id
        organisationId
        referenceNumber
        requestedAt
        status
        retentionType
        securityType
        ##EXTRA_FRAGMENTS##
      }
    }
  }
`

export const DOCUMENT_QUERY = gql`
query DocumentById($documentId: ID!) {
  document: documentById(id: $documentId) {
    ${DOCUMENT_QUERY_SHARED_FRAGMENT}
  }
}
`

export const DOCUMENT_BY_DRAFT_ID_QUERY = gql`
query DocumentByDraftId($id: ID!) {
  document: documentByDraftId(id: $id) {
    ${DOCUMENT_QUERY_SHARED_FRAGMENT}
  }
}`

export const DRAFT_QUERY = gql`
  query DraftByIdAndOrganisation($draftId: ID!, $organisationId: ID!) {
    draft: draftByIdAndOrganisationId(id: $draftId, organisationId: $organisationId) {
      ${DRAFT_QUERY_SHARED_FRAGMENT}
      draftComments(first: 25, order_by: { createdAt: DESC }) {
        ${DRAFT_COMMENTS_SHARED_FRAGMENT}
      }
      draftHistory(first: 25, order_by: { triggeredAt: DESC }) {
        ${DRAFT_HISTORY_SHARED_FRAGMENT}
      }
    }
  }
`

export const DOCUMENT_COMMENTS_QUERY = gql`
  query DocumentComments(
    $documentId: ID!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
  ) {
    document: documentById(id: $documentId) {
      id
      comments(after: $after, before: $before, first: $first, last: $last, order_by: { createdAt: DESC }) {
        nodes {
          id
          author {
            familyName
            givenName
          }
          content
          createdAt
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`

export const DRAFT_COMMENTS_QUERY = gql`
  query DraftComments(
    $draftId: ID!
    $organisationId: ID!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
  ) {
    draft: draftByIdAndOrganisationId(id: $draftId, organisationId: $organisationId) {
      id
      draftComments(after: $after, before: $before, first: $first, last: $last, order_by: { createdAt: DESC }) {
        ${DRAFT_COMMENTS_SHARED_FRAGMENT}
      }
    }
  }
`

export const DRAFT_HISTORY_QUERY = gql`
  query DraftHistory(
    $draftId: ID!
    $organisationId: ID!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
  ) {
    draft: draftByIdAndOrganisationId(id: $draftId, organisationId: $organisationId) {
      id
      draftHistory(after: $after, before: $before, first: $first, last: $last, order_by: { triggeredAt: DESC }) {
        ${DRAFT_HISTORY_SHARED_FRAGMENT}
      }
    }
  }
`

export const DOCUMENT_ATTACHMENT_DOWNLOAD = gql`
  query DocumentAttachmentDownload($id: ID!, $documentId: ID!) {
    documentAttachmentDownload(id: $id, documentId: $documentId) {
      downloadPresignedUrl
    }
  }
`

export const DOCUMENT_HISTORY_QUERY = gql`
  query DocumentHistory(
    $documentId: ID!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
  ) {
    document: documentById(id: $documentId) {
      id
      history(after: $after, before: $before, first: $first, last: $last, order_by: { triggeredAt: DESC }) {
        ${DRAFT_HISTORY_SHARED_FRAGMENT}
      }
    }
  }
`

export const DRAFTS_BY_ORGANISATION_QUERY = gql`
  query DraftsByOrganisation(
    $organisationId: ID!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: DraftSort
    $where: DraftFilter
    $search: String
  ) {
    drafts: draftsByOrganisationId(
      organisationId: $organisationId
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
      search: $search
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        organisationId
        status
        amount
        agreementContent
        purpose
        processType
        expiresAt
        expectedReturnDate
        feeSummary {
          securityTypeCash
          securityTypeProperty
          supplier {
            name
          }
        }
        callerContext {
          collaborator {
            roles
          }
        }
        issuers: parties(where: { partyVariant: ISSUER }) {
          name
        }
        beneficiaries: parties(where: { partyVariant: BENEFICIARY }) {
          name
        }
      }
    }
  }
`

export const DRAFTS_SHARED_WITH_ME_QUERY = gql`
  query DraftsSharedWithMe(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: DraftSort
    $where: DraftFilter
  ) {
    drafts: draftsSharedWithMe(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        organisationId
        status
        amount
        agreementContent
        purpose
        processType
        expiresAt
        expectedReturnDate
        callerContext {
          collaborator {
            roles
          }
        }
        issuers: parties(where: { partyVariant: ISSUER }) {
          name
        }
        beneficiaries: parties(where: { partyVariant: BENEFICIARY }) {
          name
        }
      }
    }
  }
`

export const FINANCIAL_SERVICES_GUIDE_HTML_STRING = gql`
  query FSGHtmlString {
    fSGHtmlString
  }
`

export const GENERATE_AGENCY_AGREEMENT_HTML_STRING_FOR_DRAFT = gql`
  query GenerateAgencyAgreementHtmlStringForDraft($draftId: ID!) {
    generateAgencyAgreementHtmlStringForDraft(draftId: $draftId)
  }
`

export const GENERATE_PRODUCT_PREVIEW_TEMPLATE_HTML_STRING_FOR_DRAFT = gql`
  query GenerateProductPreviewTemplateHtmlStringForDraft($draftId: ID!, $feeSummaryId: ID!) {
    generateProductPreviewTemplateHtmlStringForDraft(draftId: $draftId, feeSummaryId: $feeSummaryId)
  }
`

export const PROFILE_QUERY = gql`
  query MyProfile {
    me {
      address
      createdAt
      email
      familyName
      givenName
      id
      industry
      jobTitle
      locale
      phoneNumber
      timezone
    }
  }
`

export const MY_INVITATION_QUERY = gql`
  query MyOrganisationInvitation($code: ID!) {
    me {
      invitations(where: { code: $code }) {
        edges {
          node {
            invitedTo
            organisationId
          }
        }
      }
    }
  }
`

export const ALERTS_QUERY = gql`
  query OrganisationAlerts($organisationId: ID!) {
    organisations(where: { id: $organisationId }) {
      nodes {
        capabilities
        alertConfigurations {
          id
          emailAddresses
          triggerLeadTime
          triggerProperty
          facilityLimit
          facilityLimitIncludeProjectedDocs
          facilityId
        }
      }
    }
  }
`

export const DOCUMENTS_SHARED_WITH_ME_QUERY = gql`
  query DocumentsSharedWithMe(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order_by: DocumentSort
    $where: DocumentFilter
    $search: String
  ) {
    documentsSharedWithMe(
      after: $after
      before: $before
      first: $first
      last: $last
      order_by: $order_by
      where: $where
      search: $search
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        organisationId
        referenceNumber
        status
        documentVariant
        amount
        projectAddress
        expectedReturnDate
        expiresAt
        expectedReturnDate
        createdAt
        bank {
          name
        }
      }
    }
  }
`

export const ORGANISATION_MEMBERS_QUERY = gql`
  query OrganisationMembers(
    $organisationId: ID!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
  ) {
    organisations(where: { id: $organisationId }) {
      nodes {
        id
        capabilities
        name
        memberInvitations {
          totalCount
        }
        members: members(after: $after, before: $before, first: $first, last: $last) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          nodes {
            createdAt
            role
            familyName
            givenName
            userId
            email
          }
        }
      }
    }
  }
`

export const ORGANISATION_INVITATIONS_QUERY = gql`
  query OrganisationInvitations(
    $organisationId: ID!
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
  ) {
    organisations(where: { id: $organisationId }) {
      nodes {
        id
        name
        capabilities
        memberInvitations(after: $after, before: $before, first: $first, last: $last) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          nodes {
            email
            role
            id
          }
        }
      }
    }
  }
`

export const FACILITIES_QUERY = gql`
  query DocumentFacilities(
    $organisationId: ID!
    $where: DocumentFacilityFilter
    $order_by: DocumentFacilitySort
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
  ) {
    facilities: documentFacilities(
      organisationId: $organisationId
      where: $where
      order_by: $order_by
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        name
        provider
        limit
        startDate
        endDate
        totalEstablished
        totalProjected
        documentVariant
        documentSetUpCost
        documentAdjustedCost
        documentRecurringFixedCost
        documentRecurringPercentageCost
        documentRecurringCostFrequency
        documentRecurringFeeType
        documentRecurringMinimumAnnualCost
        capabilities
        isAssuroFacility
        organisationId
        organisation {
          name
        }
        sharedFacilityWithOrganisations {
          organisationId
          sharedWithOrganisation {
            name
          }
          createdAt
          id
          facilityId
        }
      }
    }
  }
`

export const FACILITY_QUERY = gql`
  query DocumentFacility($facilityId: ID!, $organisationId: ID!) {
    facilities: documentFacilities(organisationId: $organisationId, where: { id: $facilityId }) {
      nodes {
        id
        organisationId
        name
        provider
        limit
        startDate
        endDate
        totalEstablished
        totalProjected
        capabilities
        documentVariant
        securityType
        setUpCost
        annualCost
        documentSetUpCost
        documentAdjustedCost
        documentRecurringFixedCost
        documentRecurringPercentageCost
        documentRecurringCostFrequency
        documentRecurringFeeType
        documentRecurringMinimumAnnualCost
        bankFrontedBondsSetUpCost
        bankFrontedBondsRecurringPercentageCost
        premiumDocumentRecurringPercentageCost
        premiumBankFrontedBondsRecurringPercentageCost
        isAssuroFacility
        organisationId
        organisation {
          name
        }
        sharedFacilityWithOrganisations {
          organisationId
          sharedWithOrganisation {
            name
          }
          createdAt
          id
          facilityId
        }
      }
    }
  }
`

export const FACILITY_APPLICATIONS_LIST_QUERY = gql`
  query GetFacilityApplications($organisationId: ID!, $where: FacilityApplicationFilter) {
    facilityApplications(organisationId: $organisationId, where: $where) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        status
        applicant {
          companyName
          contactName
        }
        submission {
          submittedAt
        }
        capabilities
        sharedFacilityWithOrganisations {
          organisationId
          sharedWithOrganisation {
            name
          }
          createdAt
          id
          facilityApplicationId
        }
      }
      capabilities
    }
  }
`

export const FACILITY_APPLICATION_QUERY = gql`
  query GetFacilityApplication($facilityApplicationId: ID!) {
    facilityApplication: facilityApplicationById(id: $facilityApplicationId) {
      id
      facilityType
      applicant {
        abn
        address
        companyName
        contactName
        contactTitle
        emailAddress
        entityType
        industry
        phoneNumber
        website
      }
      applicationInformation {
        facilityLimit
      }
      entities {
        id
        abn
        name
      }
      properties {
        address
        estimatedValue
        id
        isMortgaged
        isPrimaryPlaceOfResidence
        lender
        outstandingMortgage
        propertyType
        owners {
          applicantRelationship
          id
          name
        }
      }
      submission {
        submittedAt
      }
      attachments {
        id
        contentType
        fileName
        received
        uploadPresignedUrl
        variant
        infectedFileStatus
      }
      createdAt
      sharedFacilityWithOrganisations {
        organisationId
        sharedWithOrganisation {
          name
        }
        createdAt
        id
        facilityApplicationId
      }
      organisation {
        id
      }
    }
  }
`

export const FACILITY_APPLICATION_CAPABILITIES_QUERY = gql`
  query GetFacilityApplication($facilityApplicationId: ID!) {
    facilityApplication: facilityApplicationById(id: $facilityApplicationId) {
      id
      capabilities
    }
  }
`

export const VERIFY_FACILITY_APPLICATION_QUERY = gql`
  query VerifyFacilityApplication($facilityApplicationId: ID!) {
    verifyFacilityApplication(id: $facilityApplicationId) {
      id
    }
  }
`

export const DRAFT_APPLICABLE_REQUIREMENTS = gql`
  query DraftApplicableRequirements($input: GetDraftApplicableRequirementsInput!) {
    draftApplicableRequirements(input: $input) {
      field
      message
      requirementMet
    }
  }
`

export const DRAFT_ATTACHMENT_DOWNLOAD = gql`
  query DraftAttachmentDownload($draftId: ID!, $id: ID!, $organisationId: ID) {
    draftAttachmentDownload(draftId: $draftId, id: $id, organisationId: $organisationId) {
      downloadPresignedUrl
    }
  }
`

export const DRAFT_BG_SUBMISSION_QUERY = gql`
  query DraftByIdAndOrganisation($draftId: ID!, $organisationId: ID!) {
    draft: draftByIdAndOrganisationId(id: $draftId, organisationId: $organisationId) {
      id
      expiresAt
      acceptedChannelPartnerId
      amount
      expectedReturnDate
      organisationId
      feeSummaryId
      additionalSupplierDetail {
        hasFundingAccountAuthorisation
        fundingAccountBsb
        fundingAccountNumber
      }
      feeSummary {
        biannualFeeCoefficient
        establishmentFee
        feeSummaryStatus
        details
        name
        recurringFeePercentage
        supplier {
          name
        }
      }
      establishmentFeeDiscountAmount
      discountPercentage
      issuerDetailsOfSourceOfFunds
      issuerSourceOfFunds
      refundAccountName
      refundAccountNumber
      refundBSB
      status
      invoiceRecipientEmailAddresses
      megDetails
      directorFirstName
      directorLastName
      directorEmailAddress
      completedFinanceJourney
      deliveryDetails {
        address
        recipientFamilyName
        recipientGivenName
        phoneNumber
        companyName
      }
      parties {
        name
        partyVariant
        partyEntityType
      }
      identification {
        adminStatus
        clientStatus
        companyDetailsACN
        companyDetailsIsListed
        companyDetailsIsRegulated
        companyDetailsIsSubsidiaryListed
        companyDetailsIsNormalType
        companyDetailsHasBeneficialOwners
        companyDetailsListedExchangeName
        companyDetailsName
        companyDetailsNatureOfBusiness
        companyDetailsPrincipalPlaceOfBusiness
        companyDetailsRegisteredAddress
        companyDetailsRegulatorLicenseNumber
        companyDetailsRegulatorName
        companyDetailsSubsidiaryListedCompanyName
        companyDetailsSubsidiaryListedExchangeName
        companyDetailsTaxFatcaStatus
        companyDetailsTaxFatcaStatusOther
        companyDetailsOnlyOperatesInAustralia
        companyDetailsTaxForeignResidentCountry
        companyDetailsTaxGIIN
        companyDetailsTaxHasBeneficialOwnerForeignResident
        companyDetailsTaxHasGIIN
        companyDetailsTaxIsForeignResident
        companyDetailsTaxType
        identificationIndustry
        industryAdditionalDetails
        entityWealthAccumulation
        dataType
        entityType
        hasMacAccount
        hasDividendsIncome
        id
        macAccountName
        macAccountNumber
        postalAddress
        taxExemptionDetails
        taxExemptionType
        taxStatus
        userId
        organisationId
        step
        dataType
        individuals {
          emailAddress
          firstName
          middleNames
          greenIdVerificationId
          greenIdVerificationStatus
          id
          identificationId
          isAccountSignatory
          isForeignResident
          isApprover
          isBeneficialOwner
          isDirectorOrSecretary
          isMacVerified
          isValidated
          macVerifiedNumber
          phoneMobile
          taxForeignResidentCountry
          taxpayerIdentificationNumber
          taxReasonNoTIN
          taxReasonUnavailableTIN
          surname
          title
          userRole
          simpleKycDataIndividualId
        }
        organisation {
          channelPartners {
            id
            name
          }
        }
      }
      bGSwitch {
        ${DRAFT_BG_SWITCH_FRAGMENT}
      }
    }
  }
`

export const CHANNEL_PARTNERS_ACTIVE_QUERY = gql`
  query ChannelPartners(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [ChannelPartnerSortInput!]
    $where: ChannelPartnerFilterInput
  ) {
    channelPartners: channelPartners(
      where: $where
      order: $order
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        id
        name
      }
    }
  }
`

export const GET_FEES_LIST = gql`
  query GetFeeSummaries($organisationId: ID!) {
    getFeeSummaries(organisationId: $organisationId) {
      id
      biannualFeeCoefficient
      establishmentFee
      recurringFeePercentage
      name
      details
      feeSummaryStatus
      supplier {
        name
      }
    }
  }
`

export const PRODUCT_SELECTION_SUMMARY = gql`
  query productSelectionSummary($organisationId: ID!) {
    productSelectionSummary(organisationId: $organisationId) {
      productDiscount {
        channelPartnerId
        providerName
        discountLabel
        discountType
      }
      referralDiscount {
        discountAmountInDollars
        discountType
        organisationReferralId
      }
      feeSummary {
        id
        biannualFeeCoefficient
        establishmentFee
        recurringFeePercentage
        name
        details
        minValue
        maxValue
        feeSummaryStatus
        supplier {
          name
        }
      }
    }
  }
`

export const FINANCE_ELIGIBILITY = gql`
  query CalculateAdvertisedFinance($draftId: ID!) {
    calculateAdvertisedFinance(draftId: $draftId) {
      availabilityPeriodMinBusinessDays
      availabilityPeriodMaxBusinessDays
      financeAmount
      repaymentOptions {
        months
        advertisedFortnightlyRepayments
      }
    }
  }
`

export const REFERRAL_CONFIGURATION = gql`
  query referralConfiguration {
    referralConfiguration {
      refereeDiscountAmountInDollars
      referrerDiscountAmountInDollars
    }
  }
`

export const SOURCE_OF_FUNDS_REF_DATA_DRAFT_ID = gql`
  query sourceOfFundsReferenceData_ByDraftId($draftId: ID!) {
    sourceOfFundsReferenceData_ByDraftId(draftId: $draftId) {
      id
      key
      description
    }
  }
`

export const STRIPE_INTERFACE = gql`
  query getPaymentIntentForInvoice($draftId: ID!, $paymentIntentId: String!, $organisationId: ID!) {
    getPaymentIntentForInvoice(draftId: $draftId, organisationId: $organisationId, paymentIntentId: $paymentIntentId) {
      clientSecret
      paymentMethodId
    }
  }
`

export const GET_GENERATED_ATTACHMENTS = gql`
  query generatedAttachmentsForOrganisation($organisationId: ID!, $order_by: DraftAttachmentSort) {
    generatedAttachmentsForOrganisation(organisationId: $organisationId, order_by: $order_by) {
      nodes {
        id
        fileName
        variant
        createdAt
        draftId
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`

export const GET_BG_APPLICATION_CONTACT_DETAILS = gql`
  query ApplicationContactDetails($organisationId: ID!, $draftId: ID!) {
    applicationContactDetails(organisationId: $organisationId, draftId: $draftId) {
      firstName
      lastName
      email
      mobilePhone
      businessPhone
      role
    }
  }
`

export const GET_DRAFT_REQUIRED_DOCUMENTS = gql`
  query DraftByIdAndOrganisation($draftId: ID!, $organisationId: ID!) {
    draft: draftByIdAndOrganisationId(id: $draftId, organisationId: $organisationId) {
        ${REQUIRED_DOCUMENTS_QUERY_SHARED_FRAGMENT}

    }
  }
`
export const GET_REFERRAL_PORTAL_ENTITIES = gql`
  query ReferralPortalEntitiesForClientPortal(
    $after: String
    $before: String
    $first: PaginationAmount
    $last: PaginationAmount
    $order: [EntitySortInput!]
    $where: EntityFilterInput
  ) {
    referralPortalEntitiesForClientPortal(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      nodes {
        id
        name
      }
    }
  }
`
